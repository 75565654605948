import { gql } from '@apollo/client';

const CREATE_DEFAULT_DEBT_FILE = gql`
  mutation createDefaultDebtSimulationFile(
    $invoiceIds: [Int]!
    $invoiceDates: [DateTime]!
    $documentFormat: DefaultDebtFormatEnum!
  ) {
    createDefaultDebtSimulationFile(
      invoiceIds: $invoiceIds
      invoiceDates: $invoiceDates
      documentFormat: $documentFormat
    ) {
      url
    }
  }
`;

export default CREATE_DEFAULT_DEBT_FILE;
