import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GET_INSTALLMENTS } from '@fingo/lib/graphql';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import useInstallmentColumns from '@fingo/lib/constants/useInstallmentColumns';
import { MasterEntityType } from '@fingo/lib/propTypes';
import InstallmentActions from './InstallmentActions';

const Installments = ({ amortizationSchedule }) => {
  const columns = useInstallmentColumns(amortizationSchedule);
  const [installmentIds, setInstallmentIds] = useState([]);
  return (
    <Stack sx={{ height: 410 }}>
      <FingoMainView
        id="installments"
        query={GET_INSTALLMENTS}
        queryCustomVariables={{
          amortizationScheduleId: amortizationSchedule.globalAppId,
        }}
        slots={{
          table: FingoDataGrid,
          actions: InstallmentActions,
        }}
        slotProps={{
          table: {
            checkboxSelection: true,
            columns,
            initialOrderBy: 'expirationDate',
            noRowsMessage: () => <Typography>Sin cuotas</Typography>,
            onSelectionModelChange: setInstallmentIds,
            rowsPerPageOptions: [10, 25, 50, 100],
          },
          actions: {
            installmentIds,
            setInstallmentIds,
            debtor: amortizationSchedule.masterEntity,
          },
        }}
      />
    </Stack>
  );
};

Installments.propTypes = {
  amortizationSchedule: PropTypes.shape({
    masterEntity: MasterEntityType.isRequired,
    globalAppId: PropTypes.string.isRequired,
  }).isRequired,
};

export default Installments;
