/* eslint-disable operator-linebreak */
import { useQuery } from '@apollo/client';
import StepperDialog from '@fingo/lib/components/dialogs/StepperDialog';
import { CONTACT_COLUMNS } from '@fingo/lib/constants/contact-columns';
import useSendCollectionMailSteps from '@fingo/lib/hooks/useSendCollectionMailSteps';
import { MasterEntityType } from '@fingo/lib/propTypes/CustomersType';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import useAddCollectionActionSteps from '../../../hooks/useAddCollectionActionSteps';
import useAddDataForCollectionSteps from '../../../hooks/useAddDataForCollectionSteps';
import useAddDefaultDebtCalculatorSteps from '../../../hooks/useAddDefaultDebtCalculatorSteps';
import {
  COLLECTION_INFO,
  DEFAULT_CALCULATOR,
  NEW_ACTION,
  NEW_MAIL,
  useDebtorActionButtons,
} from './DebtorActionButtons';

const FactoringDebtorActions = ({ debtor, selectedDocumentIds, query }) => {
  const [openAddCollectionActionDialog, setOpenAddCollectionActionDialog] = useState(false);
  const [openSendCollectionMailDialog, setOpenSendCollectionMailDialog] = useState(false);
  const [openAddDataForCollectionDialog, setOpenAddDataForCollectionDialog] = useState(false);
  const [openDefaultDebtCalculatorDialog, setOpenDefaultDebtCalculatorDialog] = useState(false);

  const { data, loading } = useQuery(query, {
    variables: {
      id_In: selectedDocumentIds,
      first: selectedDocumentIds.length,
    },
    skip: !selectedDocumentIds.length,
  });

  const buttonHandlers = useMemo(() => ({
    [DEFAULT_CALCULATOR]: () => setOpenDefaultDebtCalculatorDialog(true),
    [NEW_ACTION]: () => setOpenAddCollectionActionDialog(true),
    [NEW_MAIL]: () => setOpenSendCollectionMailDialog(true),
    [COLLECTION_INFO]: () => setOpenAddDataForCollectionDialog(true),
  }), []);

  const buttons = useDebtorActionButtons({
    loading,
    selectedDocumentIds,
    handlers: buttonHandlers,
    type: 'Factoring',
  });

  const documents =
    useMemo(() => Object.values(data || [])?.[0]?.edges?.map((doc) => doc?.node) || [], [data]);

  const [addCollectionActionSteps, addCollectionActionResetFields] = useAddCollectionActionSteps(
    debtor,
    documents,
  );

  const [addDataForCollectionSteps, addDataForCollectionResetFields] = useAddDataForCollectionSteps(
    debtor,
    documents,
  );

  const [sendCollectionMailSteps, sendCollectionMailResetFields] = useSendCollectionMailSteps(
    debtor,
    documents,
    true,
    true,
    CONTACT_COLUMNS,
  );

  const [addDefaultDebtCalculatorSteps, addDefaultDebtCalculatorResetFields] =
    useAddDefaultDebtCalculatorSteps(documents);

  return (
    <Box height="100%">
      <Stack sx={{ borderRadius: 2, px: 1, width: 'auto', height: 100 }} id="debtor-actions" direction="column" spacing={1}>
        {buttons.buttons}
      </Stack>
      <StepperDialog
        open={openDefaultDebtCalculatorDialog}
        setOpen={setOpenDefaultDebtCalculatorDialog}
        handleClose={addDefaultDebtCalculatorResetFields}
        steps={addDefaultDebtCalculatorSteps}
      />
      <StepperDialog
        open={openAddCollectionActionDialog}
        setOpen={setOpenAddCollectionActionDialog}
        handleClose={addCollectionActionResetFields}
        steps={addCollectionActionSteps}
      />
      <StepperDialog
        open={openAddDataForCollectionDialog}
        setOpen={setOpenAddDataForCollectionDialog}
        handleClose={addDataForCollectionResetFields}
        steps={addDataForCollectionSteps}
      />
      <StepperDialog
        open={openSendCollectionMailDialog}
        setOpen={setOpenSendCollectionMailDialog}
        handleClose={sendCollectionMailResetFields}
        steps={sendCollectionMailSteps}
      />
    </Box>
  );
};

FactoringDebtorActions.propTypes = {
  debtor: MasterEntityType,
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object.isRequired,
};

FactoringDebtorActions.defaultProps = {
  debtor: null,
};

export default FactoringDebtorActions;
