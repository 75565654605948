import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MailOutlined from '@mui/icons-material/MailOutlined';
import { useSendCollectionMailSteps } from '@fingo/lib/hooks';
import { CONTACT_COLUMNS } from '@fingo/lib/constants/collection-summary';
import { MasterEntityType } from '@fingo/lib/propTypes';
import { GET_INSTALLMENTS_BY_IDS } from '@fingo/lib/graphql';
import StepperDialog from '@fingo/lib/components/dialogs/StepperDialog';
import AddActionButton from '../../../../components/buttons/AddActionButton';
import { collectionPaymentPlanReceiverActionTypes } from '../../constants/actions-type';

const InstallmentActions = (
  {
    installmentIds,
    setInstallmentIds,
    debtor,
  },
) => {
  const { data, loading } = useQuery(GET_INSTALLMENTS_BY_IDS, {
    variables: {
      id_In: installmentIds,
      first: installmentIds.length,
    },
    skip: !installmentIds.length || !debtor?.id,
  });
  const installmentManagers = Object.values(data || [])?.[0]?.edges?.map(
    (doc) => doc?.node.installmentManager,
  ) || [];
  const collectionManagersIds = installmentManagers.map(
    (installmentManager) => installmentManager.collectionManager.id,
  );
  const [openSendCollectionMailDialog, setOpenSendCollectionMailDialog] = useState(false);
  const [sendCollectionMailSteps, sendCollectionMailResetFields] = useSendCollectionMailSteps(
    debtor,
    installmentManagers,
    false,
    true,
    CONTACT_COLUMNS,
  );
  return (
    <>
      <Grid container justifyContent="flex-end" sx={{ gap: 1 }}>
        <Grid item>
          <Button
            id="send-collection-mail-button"
            color="primary"
            size="small"
            variant="contained"
            startIcon={<MailOutlined />}
            sx={{ minWidth: 'fit-content', height: 0.7, marginTop: 0.3 }}
            onClick={() => setOpenSendCollectionMailDialog(true)}
            disabled={installmentIds.length === 0 || loading}
          >
            <Typography>Nuevo mail</Typography>
          </Button>
        </Grid>
        <Grid item>
          <AddActionButton
            managerIds={collectionManagersIds}
            operationType="COLLECTION"
            disabled={installmentIds.length === 0 || loading}
            masterEntityTarget={debtor}
            actionsType={collectionPaymentPlanReceiverActionTypes}
          />
        </Grid>
      </Grid>
      <StepperDialog
        open={openSendCollectionMailDialog}
        setOpen={setOpenSendCollectionMailDialog}
        handleClose={() => {
          sendCollectionMailResetFields();
        }}
        onSubmit={() => {
          sendCollectionMailResetFields();
          setInstallmentIds([]);
        }}
        steps={sendCollectionMailSteps}
      />
    </>
  );
};

InstallmentActions.propTypes = {
  installmentIds: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  setInstallmentIds: PropTypes.func.isRequired,
  debtor: MasterEntityType,
};

InstallmentActions.defaultProps = {
  debtor: null,
};

export default InstallmentActions;
