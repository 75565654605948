import { gql } from '@apollo/client';

const CALCULATE_DEFAULT_DEBT = gql`
  mutation calculateDefaultDebtSimulation(
    $invoiceIds: [Int]!
    $invoiceDates: [DateTime]!
  ) {
    calculateDefaultDebtSimulation(
      invoiceIds: $invoiceIds
      invoiceDates: $invoiceDates
    ) {
      amount {
        amount
        currency {
          code
          name
        }
      }
      defaultDebt {
        amount
        currency {
          code
          name
        }
      }
    }
  }
`;

export default CALCULATE_DEFAULT_DEBT;
